<template>
  <div>
    <div class="flex justify-between">
      <h1 class="text-xl font-headers font-bold text-grey-dark-2">
        View Competency
      </h1>
      <div class="flex">
        <Button text="Back" type="primary-white" size="medium" @click.native="goBack" :iconLeft="arrow" rotateArrow />
      </div>
    </div>
    <div class="bg-white shadow rounded px-2 py-3 mt-2.5 mb-3.5 max-h-816 overflow-y-scroll">
      <div class="max-w-1/2 min-w-600">
        <p class="font-headers font-bold text-mdl text-grey-light mb-2">
          A competency must contain at least one sub-competency.
        </p>
        <div class="ml-2.5">
          <div class="flex gap-2.5 mb-2">
            <InputField label="Competency Name" class="w-4/5" :disabled="true" :onChange="(value) => (name = value)"
              :defaultValue="name" />
            <ColorPicker label="Competency Color" :disabled="true" :competencyColor="color"
              :selectColor="(value) => (color = value)" />
          </div>
          <TextArea label="Description" :disabled="true" :onChange="(value) => (description = value)"
            :defaultValue="description" class="mb-3" />
          <div class="flex flex-col">
            <h3 class="text-md-2 text-grey-dark-1 font-bold mb-1">
              Sub-competencies
            </h3>
            <div>
              <div v-for="(item, index) in subCompetencies" :key="item.id" class="flex items-end mb-2">
                <InputField label="Sub-competency Name" :disabled="true"
                  :onChange="(value) => (subCompetencies = { value, index })" :defaultValue="item.name" class="w-full" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-white px-2 pt-3 pb-4 rounded shadow mb-2">
      <p class="font-barlow font-bold text-mdl text-grey-light mb-2">
        Status
      </p>
      <div class="px-2">
        <p class="text-md" :class="status ? 'text-green' : 'text-red'">
          {{ status ? "Active" : "Inactive" }}
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import add from "@/assets/img/icons/add-blue.svg";
import remove from "@/assets/img/icons/remove.svg";
import arrow from "@/assets/img/icons/arrow-right.svg";
import InputField from "../../../components/InputField/InputField.vue";
import TextArea from "../../../components/TextArea";
import ColorPicker from "../../Products/components/ColorPicker.vue";
import IconButton from "../../../components/IconButton/IconButton.vue";

export default {
  name: "CompetencyView",
  components: { InputField, ColorPicker, TextArea, IconButton },
  data: () => ({ add, remove, arrow }),
  computed: {
    ...mapState({
      competency: (state) => state.products.competency
    }),
    backRoute() {
      return `/competencies`;
    },
    showDelete() {
      return this.subCompetencies.length > 1;
    },
    description: {
      get() {
        return this.competency.description;
      }
    },
    name: {
      get() {
        return this.competency.name;
      }
    },
    color: {
      get() {
        return this.competency.color;
      }
    },
    subCompetencies: {
      get() {
        return this.competency.subCompetencies;
      }
    },
    status: {
      get() {
        return this.competency.active;
      }
    },
  },
  methods: {
    goBack() {
      this.$router.push(this.backRoute);
    }
  },
};
</script>
<style lang="scss" scoped>
.iconButton {
  background-color: #eff7fd;
  height: 30px;
  width: 30px;
  margin-bottom: 3px;
}
</style>
